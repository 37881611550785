import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-spells"></a><h2>Epic Spells</h2>
    <a id="developing-epic-spells"></a><h3>DEVELOPING EPIC SPELLS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
An epic spell is developed from smaller pieces called seeds and
connecting pieces called factors. Every epic seed has a base Spellcraft
DC, and every factor has a Spell-craft DC adjustment. When a desired
spell is developed, the spellcaster spends resources and time to
assemble the pieces that make up the epic spell. The base Spellcraft
DCs of each seed are added together; then the DC adjustments of the
factors are added to that total. The sum equals the final Spellcraft DC
for the epic spell. 
    <p>The final Spellcraft DC is the most significant gauge of the
epic spell&rsquo;s power. A spellcaster attempts to cast an epic spell
by
making a Spellcraft check against the epic spell&rsquo;s Spellcraft DC.
Thus,
a spellcaster knows immediately, based on his or her own Spellcraft
bonus, what epic spells are within his or her capability to cast, which
are risky, and which are beyond him or her. Epic casters don&rsquo;t
commit
time and money to develop epic spells until they are powerful enough to
cast them. </p>
    <p>An epic spell developed by an arcane spellcaster is arcane,
and an epic spell developed by a divine spellcaster is divine. A
character who can cast both divine and arcane epic spells chooses
whether a particular spell he or she develops will be arcane or divine.
If that same caster uses the heal or life seed in an epic spell, that
spell is always considered divine. All the epic spells described here
can be developed independently by a character who spends the necessary
time, money, and experience points. Alternatively, a character can use
those spells as a starting point when creating customized versions of
the spells. </p>
    <a id="table-epic-spell-seeds"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "450px"
    }}>
      <tbody>
        <tr valign="bottom">
          <th>Seed
          </th>
          <th>Base Spellcraft DC
          </th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Seed</span>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Base Spellcraft DC</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>Afflict
          </td>
          <td>14</td>
          <td style={{
            "verticalAlign": "top"
          }}>Energy</td>
          <td style={{
            "verticalAlign": "top"
          }}>19</td>
        </tr>
        <tr>
          <td>Animate</td>
          <td>25</td>
          <td style={{
            "verticalAlign": "top"
          }}>Foresee</td>
          <td style={{
            "verticalAlign": "top"
          }}>17
          </td>
        </tr>
        <tr className="odd-row">
          <td>Animate dead</td>
          <td>23</td>
          <td style={{
            "verticalAlign": "top"
          }}>Fortify</td>
          <td style={{
            "verticalAlign": "top"
          }}>17
          </td>
        </tr>
        <tr>
          <td>Armor</td>
          <td>14</td>
          <td style={{
            "verticalAlign": "top"
          }}>Heal*</td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
        </tr>
        <tr className="odd-row">
          <td>Banish</td>
          <td>27</td>
          <td style={{
            "verticalAlign": "top"
          }}>Life*</td>
          <td style={{
            "verticalAlign": "top"
          }}>27
          </td>
        </tr>
        <tr>
          <td>Compel</td>
          <td>19</td>
          <td style={{
            "verticalAlign": "top"
          }}>Reflect</td>
          <td style={{
            "verticalAlign": "top"
          }}>27
          </td>
        </tr>
        <tr className="odd-row">
          <td>Conceal</td>
          <td>17</td>
          <td style={{
            "verticalAlign": "top"
          }}>Reveal</td>
          <td style={{
            "verticalAlign": "top"
          }}>19
          </td>
        </tr>
        <tr>
          <td>Conjure</td>
          <td>21</td>
          <td style={{
            "verticalAlign": "top"
          }}>Slay</td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
        </tr>
        <tr className="odd-row">
          <td>Contact</td>
          <td>23</td>
          <td style={{
            "verticalAlign": "top"
          }}>Summon</td>
          <td style={{
            "verticalAlign": "top"
          }}>14
          </td>
        </tr>
        <tr>
          <td className="last-row">Delude</td>
          <td className="last-row">14</td>
          <td style={{
            "verticalAlign": "top"
          }}>Transform</td>
          <td style={{
            "verticalAlign": "top"
          }}>21</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Destroy</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>29</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Transport</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>27
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Dispel</td>
          <td style={{
            "verticalAlign": "top"
          }}>19</td>
          <td style={{
            "verticalAlign": "top"
          }}>Ward</td>
          <td style={{
            "verticalAlign": "top"
          }}>14
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="4"><span style={{
              "fontStyle": "italic"
            }}>*Spellcasters without at least 24 ranks in
Knowledge (religion) or Knowledge (nature) may not use heal or life
spell seeds.</span>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="resource-cost"></a><p><span style={{
        "fontWeight": "bold"
      }}>Resource Cost:</span> The
development of an epic spell uses up raw materials costing a number of
gold pieces equal to 9,000 xthe final Spellcraft DC of the epic spell
being developed. </p>
    <a id="development-time"></a><p><span style={{
        "fontWeight": "bold"
      }}>Development Time:</span>
Developing an epic spell takes one day for each 50,000 gp in resources
required to develop the spell, rounded up to whole days. </p>
    <a id="xp-cost"></a><p><span style={{
        "fontWeight": "bold"
      }}>XP Cost:</span> To develop an
epic spell, a character must spend 1/25 of its resource price in
experience points. </p>
    <a id="addin-seed-dcs"></a><p><span style={{
        "fontWeight": "bold"
      }}>Adding Seed DCs:</span> When
two or more epic seeds are combined in an epic spell, their base
Spellcraft DCs are added together. Both contribute toward the
spell&rsquo;s
final Spellcraft DC. </p>
    <a id="determining-school"></a><p><span style={{
        "fontWeight": "bold"
      }}>Determining School:</span>
When combining two or more seeds to develop an epic spell, the school
of the finished spell is decided by the caster from among the seeds
that make up the epic spell. </p>
    <a id="combining-descriptors"></a><p><span style={{
        "fontWeight": "bold"
      }}>Combining Descriptors:</span>
When two or more epic seeds are combined in an epic spell, all the
descriptors from each seed apply to the finished spell. </p>
    <a id="combining-components-and-casting-times"></a><p><span style={{
        "fontWeight": "bold"
      }}>Combining Components and
Casting Times:</span> Almost every epic spell has verbal and somatic
components and a 1-minute casting time, regardless of the number of
epic seeds combined. The only exceptions are epic spells with the heal
and life seeds, which have divine focus components. </p>
    <a id="combining-range-targets-area-and-effect"></a><p><span style={{
        "fontWeight": "bold"
      }}>Combining Range, Targets,
Area, and Effect:</span> One seed might have a range of 12,000 feet,
another seed might have a range of 400 feet, and a third seed might not
have a range at all. Likewise, some seeds have targets, while others
have an effect or an area. To determine which seed takes precedence in
the finished epic spell, the character must decide which seed is the
base seed. The seed most important to the spell&rsquo;s overall purpose
is
the base seed, and it determines the casting time, range, target, and
so on. The other seeds apply only their specific effects to the
finished spell. It is occasionally difficult to determine a base seed
by examining the spell&rsquo;s effects. If no one seed is most
important,
simply pick one seed for the purposes of making this determination. </p>
    <a id="combining-durations"></a><p><span style={{
        "fontWeight": "bold"
      }}>Combining Durations:</span>
When combining two or more seeds to develop an epic spell, the seed
with the shortest duration determines the duration of the finished epic
spell. If any seed of an epic spell is dismissible by the caster, the
epic spell is dismissible. </p>
    <a id="saving-throws"></a><p><span style={{
        "fontWeight": "bold"
      }}>Saving Throws:</span> Even if
more than one seed has an associated saving throw, the final spell will
have only a single saving throw. If two or more seeds have the same
kind of saving throw (Fortitude, Reflex, or Will), then obviously that
will be used for the spell&rsquo;s saving throw. If the seeds have
different
kinds of saving throws, simply choose the saving throw that seems most
appropriate for the final spell. </p>
    <a id="spell-resistance"></a><p><span style={{
        "fontWeight": "bold"
      }}>Spell Resistance:</span> When
combining two or more seeds to develop an epic spell, if even one seed
is subject to spell resistance, the finished epic spell is subject to
it as well. </p>
    <a id="factors"></a><p><span style={{
        "fontWeight": "bold"
      }}>Factors:</span> Factors are
not part of epic seeds, but they are the tools used to modify specific
parameters of any given seed. Applying factors to the seeds of an epic
spell can increase or decrease the final Spellcraft DC, increase the
duration, change the area of a spell, and affect many other aspects of
the spell. </p>
    <p>There are three kinds of factors: </p>
    <ol>
      <li>Those that can affect a number of seeds. </li>
      <li>Those that can only be used with specific seeds. </li>
      <li>Those that reduce the Spellcraft DC rather than increasing
it. These are referred to as mitigating factors. To calculate the final
Spellcraft DC of an epic spell correctly, it&rsquo;s important to
determine
the mitigating factors last, after all the factors that increase the DC
have been accounted for. </li>
    </ol>
    <a id="development-is-an-art"></a><p><span style={{
        "fontWeight": "bold"
      }}>Development Is an Art:</span>
Many times developing a completely new epic spell requires some
guesswork and rule stretching. As with making and pricing magic items,
a sort of balancing act is required. Often the description of a seed
will need to be stretched for a particular spell. If necessary, assess
an &ldquo;ad hoc&rdquo; Spellcraft DC adjustment for any effect that
cannot be
extrapolated from the seeds and factors presented here-the example
spells use ad hoc factors frequently. In all cases, the GM determines
the actual Spellcraft DC of the new spell</p>
    <a id="approval"></a><p><span style={{
        "fontWeight": "bold"
      }}>Approval:</span> This is the
final step, and it&rsquo;s critically important. The epic spell
development
work and reasoning must be shown to the GM and receive his or her
approval. If the GM doesn&rsquo;t approve, then the epic spell cannot
be
developed. However, the GM should explain why the epic spell
wasn&rsquo;t
approved and possibly offer suggestions on how to create an epic spell
that will be acceptable.
    </p>
    <a id="epic-psionic-seeds"></a><h6>Epic Psionic Seeds </h6>
Psionic characters can acquire epic powers.&nbsp; Generally, all the
epic
spell rules work for epic powers as well, except as noted below for
displays.
    <p>Psionic characters take the Epic Manifestation feat, which
works
just like the Epic Spellcasting feat. The prerequisites for this feat
are 24 ranks of Psicraft, 24 ranks of Knowledge (psionics), and the
ability to manifest 9th-level psionic powers. </p>
    <p>Just as spellcasters use no spell slots to cast epic spells,
psionic
characters use no power points to manifest epic powers. Instead, they
freely manifest their known epic powers a number of times per day equal
to their Knowledge (psionics) skill divided by 10 (round down).</p>
    <a id="table-psionic-seeds"></a><span style={{
      "fontWeight": "bold"
    }}>Table: Psionic Seeds </span>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "450px"
    }}>
      <tbody>
        <tr valign="bottom">
          <th>
          </th>
          <th>Base Psicraft DC
          </th>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Base Psicraft DC</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td><span style={{
              "fontStyle": "italic"
            }}>Psychometabolism</span>
          </td>
          <td>17
          </td>
          <td style={{
            "verticalAlign": "top",
            "fontStyle": "italic"
          }}>Telepathy</td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
        </tr>
        <tr>
          <td>Fortify</td>
          <td>25
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Compel</td>
          <td style={{
            "verticalAlign": "top"
          }}>19
          </td>
        </tr>
        <tr className="odd-row">
          <td>Slay</td>
          <td>21
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Contact</td>
          <td style={{
            "verticalAlign": "top"
          }}>23
          </td>
        </tr>
        <tr>
          <td>Transform</td>
          <td>50
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Delude</td>
          <td style={{
            "verticalAlign": "top"
          }}>14
          </td>
        </tr>
        <tr className="odd-row">
          <td>Heal
          </td>
          <td>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontStyle": "italic"
            }}>Psychokinesis </span>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
        </tr>
        <tr>
          <td><span style={{
              "fontStyle": "italic"
            }}>Psychoportation</span>
          </td>
          <td>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Dispel</td>
          <td style={{
            "verticalAlign": "top"
          }}>19
          </td>
        </tr>
        <tr className="odd-row">
          <td>Banish</td>
          <td>27
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Energy</td>
          <td style={{
            "verticalAlign": "top"
          }}>19
          </td>
        </tr>
        <tr>
          <td>Summon</td>
          <td>14
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Reflect 
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>27
          </td>
        </tr>
        <tr className="odd-row">
          <td>Transport</td>
          <td>27
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Destroy 
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>29
          </td>
        </tr>
        <tr>
          <td className="last-row" style={{
            "fontStyle": "italic"
          }}>Clairsentience</td>
          <td className="last-row">
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Ward</td>
          <td style={{
            "verticalAlign": "top"
          }}>14
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Afflict
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>14
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "fontStyle": "italic"
          }}>Metacreativity</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Foresee</td>
          <td style={{
            "verticalAlign": "top"
          }}>17
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Armor</td>
          <td style={{
            "verticalAlign": "top"
          }}>14
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Reveal</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>19
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Conjure</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>21
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Conceal</td>
          <td style={{
            "verticalAlign": "top"
          }}>17
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Animate dead</td>
          <td style={{
            "verticalAlign": "top"
          }}>23
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Animate</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>25
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Life</td>
          <td style={{
            "verticalAlign": "top"
          }}>55
          </td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "47%"
          }}><a id="table-epic-spell-factors"></a><span style={{
              "fontWeight": "bold"
            }}>Table:
Epic Spell Factors
            </span>
            <table style={{
              "width": "100%"
            }} border="0" cellPadding="1" cellSpacing="0">
              <tbody>
                <tr>
                  <th style={{
                    "width": "80%"
                  }}>
                  </th>
                  <th style={{
                    "width": "10%"
                  }}>Spellcraft DC Modifier
                  </th>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}><span style={{
                      "fontWeight": "bold"
                    }}>Casting Time</span>
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Reduce casting time by 1
round (minimum 1 round)</td>
                  <td style={{
                    "width": "10%"
                  }}>+1
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>1-action casting time
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>+20
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Quickened spell (limit one
quickened action/round)</td>
                  <td style={{
                    "width": "10%"
                  }}>+28
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Contingent on specific trigger<sup>1</sup></td>
                  <td style={{
                    "width": "10%"
                  }}>+25
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Components No verbal component</td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>No somatic component</td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}><span style={{
                      "fontWeight": "bold"
                    }}>Duration</span><sup>2</sup>
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Increase duration by 100% 
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Permanent duration (apply
this factor after all other epic spell factors but before mitigating
factors)</td>
                  <td style={{
                    "width": "10%"
                  }}>x5
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Dismissible by caster (if not
already)</td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Range Increase range by 100%</td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}><span style={{
                      "fontWeight": "bold"
                    }}>Target</span><sup>3</sup>
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Add extra target within 300
ft.</td>
                  <td style={{
                    "width": "10%"
                  }}>+10
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Change from target to area
(pick area option below)</td>
                  <td style={{
                    "width": "10%"
                  }}>+10
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Change from personal to Area
(pick Area option below)</td>
                  <td style={{
                    "width": "10%"
                  }}>+15
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Change from target to touch
or ray (300-ft. range)</td>
                  <td style={{
                    "width": "10%"
                  }}>+4
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Change from touch or ranged
touch attack to target</td>
                  <td style={{
                    "width": "10%"
                  }}>+4
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}><span style={{
                      "fontWeight": "bold"
                    }}>Area</span><sup>4</sup>
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Change area to bolt (5 ft.
x300 ft. or 10 ft. x150 ft.)</td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Change area to cylinder
(10-ft. radius,&nbsp; 30 ft. high)</td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Change area to 40-ft. cone</td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Change area to four 10-ft.
cubes</td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Change area to 20-ft. radius</td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Change area to target</td>
                  <td style={{
                    "width": "10%"
                  }}>+4
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Change area to touch or ray
(close range)</td>
                  <td style={{
                    "width": "10%"
                  }}>+4
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Increase Area by 100%</td>
                  <td style={{
                    "width": "10%"
                  }}>+4
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}><span style={{
                      "fontWeight": "bold"
                    }}>Saving Throw</span>
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Increase spell&rsquo;s saving
throw
DC by +1</td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "fontWeight": "bold",
                    "width": "80%"
                  }}>Spell
Resistance</td>
                  <td style={{
                    "width": "10%"
                  }}>
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Gain +1 bonus on caster level
check to overcome target&rsquo;s spell resistance</td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Gain +1 on caster level check
to beat foe&rsquo;s dispel effect</td>
                  <td style={{
                    "width": "10%"
                  }}>+2
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "fontWeight": "bold",
                    "width": "80%"
                  }}>Other</td>
                  <td style={{
                    "width": "10%"
                  }}>
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Recorded onto stone tablet<sup>5</sup></td>
                  <td style={{
                    "width": "10%"
                  }}>x2
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Increase damage die by one
step (d20 maximum)</td>
                  <td style={{
                    "width": "10%"
                  }}>+10
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }} rowSpan="1" colSpan="2"><span style={{
                      "fontStyle": "italic"
                    }}>Unless stated otherwise,
the same factor can be applied more than once. </span>
                    <span style={{
                      "fontStyle": "italic"
                    }}>1 Each contingent
spell in use counts as a slot used from the caster&rsquo;s daily epic
spell
slots. </span>
                    <span style={{
                      "fontStyle": "italic"
                    }}>2 Seeds that
already have an instantaneous or permanent duration cannot be
increased. </span>
                    <span style={{
                      "fontStyle": "italic"
                    }}>3 When changing a
targeted or area
seed to a touch or ranged attack, the seed no longer requires a save if
it deals damage, instead requiring a successful attack roll. Seeds with
a nondamaging effect still allow the target a save. Area spells changed
to touch or ranged attacks now affect only the creature successfully
attacked. </span>
                    <span style={{
                      "fontStyle": "italic"
                    }}>4 When changing a
touch or ranged
attack seed to a targeted seed, the seed no longer requires an attack
roll if it deals damage, instead requiring a saving throw from the
target. On a failed saving throw, the target takes half damage. Area
seeds changed to targeted seeds now only affect the target. The GM
determines the most appropriate kind of saving throw for the epic
spell. </span>
                    <span style={{
                      "fontStyle": "italic"
                    }}>5 Epic spells may
only be inscribed
on stone tablets or other substances of equal or greater hardness. Once
a spell is so inscribed, another epic spellcaster can learn it without
going through the process of development. Once an inscribed epic spell
is learned by another epic spellcaster in this fashion, the tablet upon
which it is inscribed is destroyed and cannot be mended. </span></td>
                </tr>
              </tbody>
            </table>
          </th>
          <th style={{
            "width": "6%"
          }}>
          </th>
          <td style={{
            "verticalAlign": "top",
            "width": "47%"
          }}><a id="table-epic-spell-mitigating-factors"></a><span style={{
              "fontWeight": "bold"
            }}>Table: Epic Spell Mitigating Factors 
            </span>
            <table style={{
              "width": "100%"
            }} border="0" cellPadding="1" cellSpacing="0">
              <tbody>
                <tr>
                  <th style={{
                    "width": "80%"
                  }}>
                  </th>
                  <th style={{
                    "width": "10%"
                  }}>Spellcraft DC Modifier
                  </th>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Backlash 1d6 points of damage
(max d6 = caster&rsquo;s HD x2)<sup>1</sup>
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>-1
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Burn 100 XP during casting
(max 20,000 XP)</td>
                  <td style={{
                    "width": "10%"
                  }}>-1
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Increase casting time by 1
minute (max 10 minutes)<sup>2</sup></td>
                  <td style={{
                    "width": "10%"
                  }}>-2
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Increase casting time by 1
day (max 100 days)<sup>2</sup></td>
                  <td style={{
                    "width": "10%"
                  }}>-2
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Change from target, touch, or
area to personal</td>
                  <td style={{
                    "width": "10%"
                  }}>-2
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Additional participants
(ritual)&nbsp;&nbsp;&nbsp;&nbsp;
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>see Table: Additional
Participants in Rituals&nbsp;&nbsp; 
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}>Decrease damage die by one
step (d4 minimum)</td>
                  <td style={{
                    "width": "10%"
                  }}>-5
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }} rowSpan="1" colSpan="2"><span style={{
                      "fontStyle": "italic"
                    }}>Note:
Mitigating factors are always applied after all epic spell factors (see
above) are accounted for in the development of an epic spell.</span>
                    <span style={{
                      "fontStyle": "italic"
                    }}>1 The caster cannot
somehow
avoid or make him or her self immune to backlash damage. For spells
with durations longer than instantaneous, the backlash damage is per
round. If backlash damage kills a caster, no spell or method exists
that will return life to the caster&rsquo;s body without costing the
caster a
level-not even wish, true resurrection, miracle, or epic spells that
return life to the deceased. Spells that normally penalize the
recipient one level when they return him or her to life penalize a
caster killed by backlash two levels. </span>
                    <span style={{
                      "fontStyle": "italic"
                    }}>2 When increasing
the casting
time of a spell in order to reduce the Spellcraft DC, a character must
first &ldquo;use up&rdquo; the maximum of 10 minutes (for a total DC
modifier of
-20). After that, a character can continue to add days to the casting
time, with a further modifier of -2 per day, up to the maximum of 100
days.</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>
              <a id="table-psionic-factors"></a>Table: Psionic Factors
            </span>
            <table style={{
              "width": "100%"
            }} border="0" cellPadding="1" cellSpacing="0">
              <tbody>
                <tr>
                  <th style={{
                    "width": "80%"
                  }}>
                  </th>
                  <th style={{
                    "width": "10%"
                  }}>Psicraft DC Modifier
                  </th>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%"
                  }}><span style={{
                      "fontWeight": "bold"
                    }}>Discipline</span> 
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Seed within primary discipline</td>
                  <td style={{
                    "width": "10%"
                  }}>-5
                  </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "width": "80%",
                    "fontWeight": "bold"
                  }}>Display</td>
                  <td style={{
                    "width": "10%"
                  }}>
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "width": "80%"
                  }}>Hide visual display (epic
psionic seeds substitute one Vi display for V and S components)</td>
                  <td style={{
                    "width": "10%"
                  }}>+4
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <span style={{
      "fontWeight": "bold"
    }}>
    </span>
    <a id="additional-participants"></a><p><span style={{
        "fontWeight": "bold"
      }}>Additional Participants:</span>
Epic spells can be developed that specifically require additional
participants. These spells are called rituals. An epic spell developed
as a ritual requires a specific number of additional participants, who
each must use up one spell slot of a specified level for the day.
During an epic spell&rsquo;s development, the spell&rsquo;s creator
determines the
number of additional participants and the level of the spell slots to
be contributed. If the exact number of spellcasters does not partake in
the casting, or if the casters do not each contribute the proper spell
slot, the epic spell automatically fails. To participate, each
participant readies an action to contribute his or her raw spell energy
when the primary caster begins the epic spell. Additional participants
in a ritual spell reduce the Spellcraft DC, as shown on Table:
Additional Participants in Rituals. Each additional participant may
only contribute one spell slot. It doesn&rsquo;t matter whether the
additional participants are arcane or divine spellcasters; only the
level of the spell slot contributed matters. A contributed spell slot
is treated as if normally cast. A wizard may contribute either a
prepared, uncast spell slot, or an open, unprepared slot. The
Spellcraft DC adjustments for each additional participant stack. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Special:</span> A ritual
epic spell that takes longer than 1 standard action to cast requires
all extra participants to stand as if casting for the same amount of
time. If an extra participant is attacked while contributing a spell
slot, the participant must make a Concentration check as if casting a
spell of the same level as the slot contributed. If the attack disrupts
the participant in the ritual, the epic spell is not necessarily
ruined. However, the Spellcraft DC reduction that would have been
provided by that additional participant cannot be applied to the final
Spellcraft DC of the epic spell. Thus the ritual epic spell will be
harder for the primary spellcaster to cast. 
    </p>
    <a id="table-additional-participants-in-rituals"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: Additional
Participants in Rituals</span> </p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "450px"
    }}>
      <tbody>
        <tr valign="bottom">
          <th>Spell Slot Level Contributed&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
          </th>
          <th>Spellcraft DC Reduction</th>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Spell
Slot Level Contributed</td>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Spellcraft DC Reduction</span>
&nbsp;&nbsp;&nbsp; </td>
        </tr>
        <tr className="odd-row">
          <td>1st
          </td>
          <td>-1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6th
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-11
          </td>
        </tr>
        <tr>
          <td>2nd
          </td>
          <td>-3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>7th
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-13
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd
          </td>
          <td>-5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8th
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-15
          </td>
        </tr>
        <tr>
          <td>4th
          </td>
          <td>-7
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9th
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-17
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th
          </td>
          <td>-9
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Epic slot
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-19
          </td>
        </tr>
      </tbody>
    </table>
    <a id="seed-descriptions"></a><h3>SEED DESCRIPTIONS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
Each seed description hereafter follows the same format used for 0- to
9th-level spells. An additional line, Spellcraft DC, indicates the base
DC of the Spellcraft check required to cast an epic spell with this
seed.
    <h6><a id="afflict"></a><a id="seed-afflict"></a>SEED:AFFLICT </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Enchantment (Compulsion) [Fear,
Mind-Affecting] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 14 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 standard
action 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One living
creature 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 minutes 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <p>Afflicts the target with a -2 morale penalty on attack rolls,
checks, and saving throws. For each additional -1 penalty assessed on
either the target&rsquo;s attack rolls, checks, or saving throws,
increase
the Spellcraft DC by +2. A character may also develop a spell with this
seed that afflicts the target with a -1 penalty on caster level checks,
a -1 penalty to an ability score, a -1 penalty to spell resistance, or
a -1 penalty to some other aspect of the target. For each additional -1
penalty assessed in one of the above categories, increase the
Spell-craft DC by +4. This seed can afflict a character&rsquo;s ability
scores to the point where they reach 0, except for Constitution where 1
is the minimum. If a factor is applied to increase the duration of this
seed, ability score penalties instead become temporary ability damage.
If a factor is applied to make the duration permanent, any ability
score penalties become permanent ability drain. Finally, by increasing
the Spellcraft DC by +2, one of the target&rsquo;s senses can be
afflicted:
sight, smell, hearing, taste, touch, or a special sense the target
possesses. If the target fails its saving throw, the sense selected
doesn&rsquo;t function for the spell&rsquo;s duration, with all
attendant penalties
that apply for losing the specified sense. </p>
    <h6><a id="animate"></a><a id="seed-animate"></a>SEED:ANIMATE </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Transmutation </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 25 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Object or 20 cu.
ft. of matter 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 rounds 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <p>This seed can imbue inanimate objects with mobility and a
semblance of life (not actual life). The animated object attacks
whomever or whatever the caster initially designates. The animated
object can be of any nonmagical material. The caster can also animate
part of a larger mass of raw matter, such as a volume of water in the
ocean, part of a stony wall, or the earth itself, as long as the volume
of material does not exceed 20 cubic feet. For each additional 10 cubic
feet of matter animated, increase the Spellcraft DC by +1, up to 1,000
cubic feet. For each additional 100 cubic feet of matter animated after
the first 1,000 cubic feet, increase the spellcraft DC by +1. For each
additional Hit Die granted to an animated object of a given size,
increase the Spellcraft DC by +2. To animate attended objects (objects
carried or worn by another creature), increase the Spellcraft DC by
+10. </p>
    <h6><a id="animate-dead"></a><a id="seed-animate-dead"></a>SEED:ANIMATE DEAD </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Necromancy [Evil] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 23 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One or more
corpses touched 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <p>The caster can turn the bones or bodies of dead creatures into
undead that follow his or her spoken commands. The undead can follow
the caster, or they can remain in an Area and attack any creature (or a
specific type of creature) entering the place. The undead remain
animated until they are destroyed. (A destroyed undead can&rsquo;t be
animated again.) Intelligent undead can follow more sophisticated
commands. The animate dead seed allows a character to create 20 HD of
undead. For each additional 1 HD of undead created, increase the
Spellcraft DC by +1. The undead created remain under the caster&rsquo;s
control indefinitely. A caster can naturally control 1 HD per caster
level of undead creatures he or she has personally created, regardless
of the method used. If the caster exceeds this number, newly created
creatures fall under his or her control, and excess undead from
previous castings become uncontrolled (the caster chooses which
creatures are released). If the caster is a cleric, any undead he or
she commands through his or her ability to command or rebuke undead do
not count toward the limit. For each additional 2 HD of undead to be
controlled, increase the Spellcraft DC by +1. Only undead in excess of
20 HD created with this seed can be controlled using this DC
adjustment. To both create and control more than 20 HD of undead,
increase the Spellcraft DC by +3 per additional 2 HD of undead. </p>
    <p>Type of Undead: All types of undead can be created with the
animate dead seed, although creating more powerful undead increases the
Spellcraft DC of the epic spell, according to the table below. The GM
must set the Spellcraft DC for undead not included on the table, using
similar undead as a basis for comparison.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "450px"
    }}>
      <tbody>
        <tr valign="bottom">
          <th>Undead
          </th>
          <th>Spellcraft DC Modifier
          </th>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Undead
          </td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Spellcraft
DC Modifier</td>
        </tr>
        <tr className="odd-row">
          <td>Skeleton </td>
          <td>-12
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Wraith</td>
          <td style={{
            "verticalAlign": "top"
          }}>-2
          </td>
        </tr>
        <tr>
          <td>Zombie</td>
          <td>-12
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Mummy</td>
          <td style={{
            "verticalAlign": "top"
          }}>+0
          </td>
        </tr>
        <tr className="odd-row">
          <td>Ghoul</td>
          <td>-10
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Spectre</td>
          <td style={{
            "verticalAlign": "top"
          }}>+2
          </td>
        </tr>
        <tr>
          <td>Shadow</td>
          <td>-8
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Morhg</td>
          <td style={{
            "verticalAlign": "top"
          }}>+4
          </td>
        </tr>
        <tr className="odd-row">
          <td>Ghast</td>
          <td>-6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Vampire</td>
          <td style={{
            "verticalAlign": "top"
          }}>+6
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Wight</td>
          <td style={{
            "verticalAlign": "top"
          }}>-4
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Ghost</td>
          <td style={{
            "verticalAlign": "top"
          }}>+8
          </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="armor"></a><a id="seed-armor"></a>SEED: ARMOR </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation) [Force] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 14 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Creature touched
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 24 hours (D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates (harmless) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
(harmless) 
    <p>This seed grants a creature additional armor, providing a +4
bonus to Armor Class. The bonus is either an armor bonus or a natural
armor bonus, whichever the caster selects. Unlike mundane armor, the
armor seed provides an intangible protection that entails no armor
check penalty, arcane spell failure chance, or speed reduction.
Incorporeal creatures can&rsquo;t bypass the armor seed the way they
can
ignore normal armor. For each additional point of Armor Class bonus,
increase the Spellcraft DC by +2. The caster can also grant a creature
a +1 bonus to Armor Class using a different bonus type, such as
deflection, divine, or insight. For each additional point of bonus to
Armor Class of one of these types, increase the Spellcraft DC by +10. </p>
    <h6><a id="banish"></a>SEED: BANISH </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 27 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 75 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One or more
extraplanar creatures, no two of which can be more than 30 ft. apart 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <p>This seed forces extraplanar creatures out of the
caster&rsquo;s
home plane. The caster can banish up to 14 HD of extraplanar creatures.
For each additional 2 HD of extraplanar creatures banished, increase
the Spellcraft DC by +1. To specify a type or sub-type of creature
other than outsider to be banished, increase the Spellcraft DC by +20. </p>
    <h6><a id="compel"></a><a id="seed-compel"></a>SEED: COMPEL </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Enchantment (Compulsion)
[Mind-Affecting] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 19 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, M 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 75 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One living
creature 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 hours or
until completed 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <p>This seed compels a target to follow a course of activity. At
the basic level of effect, a spell using the compel seed must be worded
in such a manner as to make the activity sound reasonable. Asking the
creature to do an obviously harmful act automatically negates the
effect (unless the Spellcraft DC has been increased to avoid this
limitation; see below). To compel a creature to follow an outright
unreasonable course of action, increase the Spellcraft DC by +10. The
compelled course of activity can continue for the entire duration. If
the compelled activity can be completed in a shorter time, the spell
ends when the subject finishes what he or she was asked to do. The
caster can instead specify conditions that will trigger a special
activity during the duration. If the condition is not met before the
spell using this seed expires, the activity is not performed. </p>
    <h6><a id="conceal"></a><a id="seed-conceal"></a>SEED: CONCEAL </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Illusion (Glamer) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 17 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal or touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You or a creature
or object of up to 2,000 lb. 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 200 minutes or
until expended (D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None or
Will negates (harmless, object) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No or
Yes (harmless, object) 
    <p>This seed can conceal a creature or object touched from sight,
even from darkvision. If the subject is a creature carrying gear, the
gear vanishes too, rendering the creature invisible. A spell using the
conceal seed ends if the subject attacks any creature. Actions directed
at unattended objects do not break the spell, and causing harm
indirectly is not an attack. To create invisibility that lasts
regardless of the actions of the subject, increase the Spellcraft DC by
+4. Alternatively, this seed can conceal the exact location of the
subject so that it appears to be about 2 feet away from its true
location; this increases the Spellcraft DC by +2. The subject benefits
from a 50% miss chance as if it had total concealment. However, unlike
actual total concealment, this displacement effect does not prevent
enemies from targeting him or her normally. The conceal seed can also
be used to block divination spells, spell-like effects, and epic spells
developed using the reveal seed; this increases the Spellcraft DC by
+6. In all cases where divination magic of any level, including epic
level, is employed against the subject of a spell using the conceal
seed for this purpose, an opposed caster level check determines which
spell works. </p>
    <h6><a id="conjure"></a><a id="seed-conjure"></a>SEED: CONJURE </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Creation) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 21 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 0 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Unattended,
nonmagical object of nonliving matter up to 20 cu. ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 8 hours 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <p>This seed creates a nonmagical, unattended object of nonliving
matter of up to 20 cubic feet in volume. The caster must succeed at an
appropriate skill check to make a complex item. The seed can create
matter ranging in hardness and rarity from vegetable matter all the way
up to mithral and even adamantine. Simple objects have a natural
duration of 24 hours. For each additional cubic foot of matter created,
increase the Spellcraft DC by +2. Attempting to use any created object
as a material component or a resource during epic spell development
causes the spell to fail and the object to disappear. </p>
    <p>The conjure seed can be used in conjunction with the life and
fortify seeds for an epic spell that creates an entirely new creature,
if made permanent. To give a creature spell-like abilities, apply other
epic seeds to the epic spell that replicate the desired ability. To
give the creature a supernatural or extraordinary ability rather than a
spell-like ability, double the cost of the relevant seed. Remember that
two doublings equals a tripling, and so forth. To give a creature Hit
Dice, use the fortify seed. Each 5 hit points granted to the creature
gives it an additional 1 HD. Once successfully created, the new
creature will breed true. </p>
    <h6><a id="contact"></a><a id="seed-contact"></a>SEED: CONTACT </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Divination </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 23 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 200 minutes 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <p>This seed forges a telepathic bond with a particular creature
with which the caster is familiar (or one that the caster can currently
see directly or through magical means) and can converse back and forth.
The subject recognizes the caster if it knows him or her. It can answer
in like manner immediately, though it does not have to. The caster can
forge a communal bond among more than two creatures. For each
additional creature contacted, increase the Spellcraft DC by +1. The
bond can be established only among willing subjects, which therefore
receive no saving throw or spell resistance. For telepathic
communication through the bond regardless of language, increase the
Spellcraft DC by +4. No special influence is established as a result of
the bond, only the power to communicate at a distance. </p>
    <p>At the base Spellcraft DC of 20, a caster can also use the
contact seed to imbue an object (or creature) with a message he or she
prepares that appears as written text for the spell&rsquo;s duration or
is
spoken aloud in a language the caster knows. The spoken message can be
of any length, but the length of written text is limited to what can be
contained (as text of a readable size) on the surface of the target.
The message is delivered when specific conditions are fulfilled
according to the caster&rsquo;s desire when the spell is cast.</p>
    <h6><a id="delude"></a><a id="seed-delude"></a>SEED: DELUDE </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Illusion (Figment) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 14 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 12,000 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Visual figment
that can extend for up to twenty 30-ft. cubes (S) 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Concentration
plus 20 hours 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
disbelief (if interacted with) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <p>A spell developed with the delude seed creates the visual
illusion of an object, creature, or force, as visualized by the caster.
The caster can move the image within the limits of the size of the
effect by concentrating (the image is otherwise stationary). The image
disappears when struck by an opponent unless the caster causes the
illusion to react appropriately. For an illusion that includes audible,
olfactory, tactile, taste, and thermal aspects, increase the Spellcraft
DC by +2 per extra aspect. Even realistic tactile and thermal illusions
can&rsquo;t deal damage, however. For each additional image to be
created,
increase the Spellcraft DC by +1. For an illusion that follows a script
determined by the caster, increase the Spellcraft DC by +9. The figment
follows the script without the caster having to concentrate on it. The
illusion can include intelligible speech if desired. For an illusion
that makes any Area appear to be something other than it is, increase
the Spellcraft DC by +4. Additional components, such as sounds, can be
added as noted above. Concealing creatures requires additional spell
development using this or other seeds. </p>
    <h6><a id="destroy"></a><a id="seed-destroy"></a>SEED: DESTROY </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Transmutation </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 29 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 12,000 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature, or
up to a 10-foot cube of nonliving matter 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
half 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <p>This seed deals 20d6 points of damage to the target. The
damage is of no particular type or energy. For each additional 1d6
points of damage dealt, increase the Spellcraft DC by +2. If the target
is reduced to -10 hit points or less (or a construct, object, or undead
is reduced to 0 hit points), it is utterly destroyed as if
disintegrated, leaving behind only a trace of fine dust. Up to a
10-foot cube of nonliving matter is affected, so a spell using the
destroy seed destroys only part of any very large object or structure
targeted. The destroy seed affects even magical matter, energy fields,
and force effects that are normally only affected by the disintegrate
spell. Such effects are automatically destroyed. Epic spells using the
ward seed may also be destroyed, though the caster must succeed at an
opposed caster level check against the other spellcaster to bring down
a ward spell. </p>
    <h6><a id="dispel"></a><a id="seed-dispel"></a>SEED: DISPEL </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 19 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature,
object, or spell 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <p>This seed can end ongoing spells that have been cast on a
creature or object, temporarily suppress the magical abilities of a
magic item, or end ongoing spells (or at least their effects) within an
Area. A dispelled spell ends as if its duration had expired. The dispel
seed can defeat all spells, even those not normally subject to dispel
magic. The dispel seed can dispel (but not counter) the ongoing effects
of supernatural abilities as well as spells, and it affects spell-like
effects just as it affects spells. One creature, object, or spell is
the target of the dispel seed. The caster makes a dispel check against
the spell or against each ongoing spell currently in effect on the
object or creature. A dispel check is 1d20 + 10 against a DC of 11 +
the target spell&rsquo;s caster level. For each additional +1 on the
dispel
check, increase the Spellcraft DC by +1. If targeting an object or
creature that is the effect of an ongoing spell, make a dispel check to
end the spell that affects the object or creature. If the object
targeted is a magic item, make a dispel check against the item&rsquo;s
caster
level. If succeessful, all the item&rsquo;s magical properties are
suppressed
for 1d4 rounds, after which the item recovers on its own. A suppressed
item becomes nonmagical for the duration of the effect. An
interdimensional interface is temporarily closed. A magic item&rsquo;s
physical properties are unchanged. Any creature, object, or spell is
potentially subject to the dispel seed, even the spells of gods and the
abilities of artifacts. A character automatically succeeds on the
dispel check against any spell that he or she cast him or her self. </p>
    <h6><a id="energy"></a><a id="seed-energy"></a>SEED: ENERGY </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Evocation [Acid, Fire,
Electricity, Cold, or Sonic] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 19 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. or touched
creature or object of 2,000 lb. or less 
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> A bolt 5 ft. wide
to 300 ft. long; or a 10-ft.-radius emanation; or a wall whose Area is
up to one 200-ft. square; or a sphere or hemi-sphere with a radius of
up to 20 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
or 20 hours (see text)
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Reflex half
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <p>This seed uses whichever one of five energy types the caster
chooses: acid, cold, electricity, fire, or sonic. The caster can cast
the energy forth as a bolt, imbue an object with the energy, or create
a freestanding manifestation of the energy. If the spell developed
using the energy seed releases a bolt, that bolt instantaneously deals
10d6 points of damage of the appropriate energy type, and all in the
bolt&rsquo;s area must make a Reflex save for half damage. For each
additional 1d6 points of damage dealt, increase the Spellcraft DC by
+2. The bolt begins at the caster&rsquo;s fingertips. To imbue another
creature with the ability to use an energy bolt as a spell-like ability
at its option or when a particular condition is met, increase the
Spellcraft DC by +25. The caster can also cause a creature or object to
emanate the specific energy type out to a radius of 10 feet for 20
hours. The emanated energy deals 2d6 points of energy damage per round
against unprotected creatures (the target creature is susceptible if
not separately warded or otherwise resistant to the energy). For each
additional 1d6 points of damage emanated, increase the Spellcraft DC by
+2. The caster may also create a wall, half-circle, circle, dome, or
sphere of the desired energy that emanates the energy for up to 20
hours. One side of the wall, selected by the caster, sends forth waves
of energy, dealing 2d4 points of energy damage to creatures within 10
feet and 1d4 points of energy damage to those past 10 feet but within
20 feet. The wall deals this damage when it appears and in each round
that a creature enters or remains in the area. In addition, the wall
deals 2d6+20 points of energy damage to any creature passing through
it. The wall deals double damage to undead creatures. For each
additional 1d4 points of damage, increase the Spellcraft DC by +2. </p>
    <p>The caster can also use the energy seed to create a spell that
carefully releases and balances the emanation of cold, electricity, and
fire, creating specific weather effects for a period of 20 hours. Using
the energy seed this way has a base Spellcraft DC of 25. The area
extends to a two-mile-radius centered on the caster. Once the spell is
cast, the weather takes 10 minutes to manifest. Ordinarily, a caster
can&rsquo;t directly target a creature or object, though indirect
effects are
possible. This seed can create cold snaps, heat waves, thunderstorms,
fogs, blizzards-even a tornado that moves randomly in the affected
area. Creating targeted damaging effects requires an additional use of
the energy seed.&nbsp;</p>
    <h6><a id="foresee"></a><a id="seed-foresee"></a>SEED: FORESEE </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Divination</span> 
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 17 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
or concentration (see text) 
    <p>The caster can foretell the immediate future, or gain
information about specific questions. He or she is 90% likely to
receive a meaningful reading of the future of the next 30 minutes. If
successful, the caster knows if a particular action will bring good
results, bad results, or no result. For each additional 30 minutes into
the future, multiply the Spellcraft DC by x2. For better results, the
caster can pose up to ten specific questions (one per round while he or
she concentrates) to unknown powers of other planes, but the base
Spellcraft DC for such an attempt is 23.&nbsp; The answers return in a
language the caster understands, but use only one-word replies:
&ldquo;yes,&rdquo;
&ldquo;no,&rdquo; &ldquo;maybe,&rdquo; &ldquo;never,&rdquo;
&ldquo;irrelevant,&rdquo; or some other one-word answer.
Unlike 0- to 9th-level spells of similar type, all questions answered
are 90% likely to be answered truthfully. However, a specific spell
using the foresee seed can only be cast once every five weeks. The
foresee seed is also useful for epic spells requiring specific
information before functioning, such as spells using the reveal and
transport seeds. The foresee seed can also be used to gain one basic
piece of information about a living target: level, class, alignment, or
some special ability (or one of an object&rsquo;s magical abilities, if
any).
For each additional piece of information revealed, increase the
Spellcraft DC by +2. </p>
    <h6><a id="fortify"></a><a id="seed-fortify"></a>SEED: FORTIFY </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Transmutation </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 17 (see
text) 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Creature touched
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 hours;
permanent for age adjustment 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates (harmless) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
(harmless) 
    <p>Spells using the fortify seed grant a +1 enhancement bonus to
whichever one of the following the caster chooses: </p>
    <p>-Any one ability score. </p>
    <p>-Any one kind of saving throw. </p>
    <p>-Spell resistance. </p>
    <p>-Natural armor. </p>
    <p>The fortify seed can also grant energy resistance 1 for one
energy type or 1 temporary hit point. For each additional +1 bonus,
point of energy resistance, or hit point, increase the Spellcraft DC by
+2. </p>
    <p>The fortify seed has a base Spellcraft DC of 23 if it grants a
+1 bonus of a type other than enhancement. For each additional +1 bonus
of a type other than enhancement, increase the Spellcraft DC by +6. If
the caster applies a factor to make the duration permanent, the bonus
must be an inherent bonus, and the maximum inherent bonus allowed is
+5. </p>
    <p>The fortify seed has a base Spellcraft DC of 27 if it grants a
creature a +1 bonus to an ability score or other statistic it does not
possess. For each additional +1 bonus, increase the Spellcraft DC by
+4. If a spell with the fortify seed grants an inanimate object an
ability score it would not normally possess (such as Intelligence), the
spell must also incorporate the life seed. </p>
    <p>Granting spell resistance to a creature that doesn&rsquo;t
already
have it is a special case; the base Spellcraft DC of 27 grants spell
resistance 25, and each additional point of spell resistance increases
the Spellcraft DC by +4 (each -1 to spell resistance reduces the
Spellcraft DC by -2).</p>
    <p>The fortify seed can also grant damage reduction 1/magic. For
each additional point of damage reduction, increase the Spellcraft DC
by +2. To increase the damage reduction value to epic, increase the
Spellcraft DC by +15.</p>
    <p>A special use of the fortify seed grants the target a
permanent +1 year to its current age category. For each additional +1
year added to the creature&rsquo;s current age category, increase the
Spellcraft DC by +2. Incremental adjustments to a creature&rsquo;s
maximum
age do not stack; they overlap. When a spell increases a
creature&rsquo;s
current age category, all higher age categories are also adjusted
accordingly.</p>
    <h6><a id="heal"></a><a id="seed-heal"></a>SEED: HEAL </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Healing) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 25 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, DF 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Creature touched 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Yes
(harmless; see text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
(harmless) 
    <p>Spells developed with the heal seed channel positive energy
into a creature to wipe away disease and injury. Such a spell
completely cures all diseases, blindness, deafness, hit point damage,
and temporary ability damage. To restore permanently drained ability
score points, increase the Spellcraft DC by +6. The heal seed
neutralizes poisons in the subject&rsquo;s system so that no additional
damage or effects are suffered. It offsets feeblemindedness and cures
mental disorders caused by spells or injury to the brain. It dispels
all magical effects penalizing the character&rsquo;s abilities,
including
effects caused by spells, even epic spells developed with the afflict
seed. Only a single application of the spell is needed to
simultaneously achieve all these effects. This seed does not restore
levels or Constitution points lost due to death. To dispel all negative
levels afflicting the target, increase the Spellcraft DC by +2. This
reverses level drains by a force or creature. The drained levels are
restored only if the creature lost the levels within the last 20 weeks.
For each additional week since the levels were drained, increase the
Spellcraft DC by +2. </p>
    <p>Against undead, the influx of positive energy causes the loss
of all but 1d4 hit points if the undead fails a Fortitude saving throw.
    </p>
    <p>An epic caster with
24 ranks in Knowledge (arcana), Knowledge (nature), or
Knowledge (religion) can cast a spell developed with a
special version of the heal seed that flushes negative
energy into the subject, healing undead completely but
causing the loss of all but 1d4 hit points in living
creatures if they fail a Fortitude saving throw.
Alternatively, a living target that fails its Fortitude saving
throw could gain four negative levels for the next 8
hours. For each additional negative level bestowed,
increase the Spellcraft DC by +4, and for each extra hour
the negative levels persist, increase the Spellcraft DC by
+2. If the subject has at least as many negative levels as
Hit Dice, it dies. If the subject survives and the negative
levels persist for 24 hours or longer, the subject must
make another Fortitude saving throw, or the negative
levels are converted to actual level loss.</p>
    <h6><a id="life"></a><a id="seed-life"></a>SEED: LIFE </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Healing) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 27 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S, DF 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Dead creature
touched 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None (see
text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
(harmless) 
    <p>A spell developed with the life seed will restore life and
complete vigor to any deceased creature. The condition of the remains
is not a factor. So long as some small portion of the creature&rsquo;s
body
still exists, it can be returned to life, but the portion receiving the
spell must have been part of the creature&rsquo;s body at the time of
death.
(The remains of a creature hit by a disintegrate spell count as a small
portion of its body.) The creature can have been dead for no longer
than two hundred years. For each additional ten years, increase the
Spellcraft DC by +1. The creature is immediately restored to full hit
points, vigor, and health, with no loss of prepared spells. However,
the subject loses one level (or 1 point of Constitution if the subject
was 1st level). The life seed cannot revive someone who has died of old
age. </p>
    <p>An epic caster with 24
ranks in Knowledge (arcana), Knowledge (nature), or
Knowledge (religion) can cast a spell developed with a
special version of the life seed that gives actual life to
normally inanimate objects. You can give inanimate
plants and animals a soul, personality, and humanlike
sentience. To succeed, you must make a Will save (DC
10 + the target�s Hit Dice, or the Hit Dice a plant will
have once it comes to life).</p>
    <p>The newly living object, intelligent animal, or sentient plant
is friendly toward the caster. An object or plant has characteristics
as if it were an animated object, except that its Intelligence, Wisdom,
and Charisma scores are all 3d6. Animated objects and plants gain the
ability to move their limbs, projections, roots, carved legs and arms,
or other appendages, and have senses similar to a human&rsquo;s. A
newly
intelligent animal gets 3d6 Intelligence, +1d3 Charisma, and +2 HD.
Objects, animals, and plants speak one language that the caster knows,
plus one additional language that he or she knows per point of
Intelligence bonus (if any). </p>
    <h6><a id="reflect"></a><a id="seed-reflect"></a>SEED: REFLECT </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 27 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Until expended
or 12 hours 
    <p>Attacks targeted against the caster rebound on the original
attacker. Each use of the reflect seed in an epic spell is effective
against one type of attack only: spells (and spell-like effects),
ranged attacks, or melee attacks. To reflect an area spell, where the
caster is not the target but are caught in the vicinity, increase the
Spellcraft DC by +20. A single successful use of reflect expends its
protection. Spells developed with the reflect seed against spells and
spell-like effects return all spell effects of up to 1st level. For
each additional level of spells to be reflected, increase the
Spellcraft DC by +20. Epic spells are treated as 10th-level spells for
this purpose. </p>
    <p>The desired effect
is automatically reflected if the spell in question is 9th
level or lower. An opposed caster level check is required
when the reflect seed is used against another epic spell. If
the enemy spellcaster gets his spell through by winning
the caster level check, the epic spell using the reflect
seed is not expended, just momentarily suppressed.</p>
    <p>If the reflect seed is used against a melee attack or ranged
attack, five such attacks are automatically reflected back on the
original attacker. For each additional attack reflected, increase the
Spellcraft DC by +4. The reflected attack rebounds on the attacker
using the same attack roll. Once the allotted attacks are reflected,
the spell using the reflect seed is expended.</p>
    <h6><a id="reveal"></a><a id="seed-reveal"></a>SEED: REVEAL </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Divination </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 19 (see
text) 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> See text 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Magical sensor 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 minutes (D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No 
    <p>The caster of this seed can see some distant location or hear
the sounds at some distant location almost as if he or she was there.
To both hear and see, increase the Spellcraft DC by +2. Distance is not
a factor, but the locale must be known-a place familiar to the caster
or an obvious one. The spell creates an invisible sensor that can be
dispelled. Lead sheeting or magical protection blocks the spell, and
the caster senses that the spell is so blocked. If the caster prefers
to create a mobile sensor (speed 30 feet) that he or she controls,
increase the Spellcraft DC by +2. To use the reveal seed to reach one
specific different plane of existence, increase the Spellcraft DC by
+8. To allow magically enhanced senses to work through a spell built
with the reveal seed, increase the Spellcraft DC by +4. To cast any
spell from the sensor whose range is touch or greater, increase the
Spellcraft DC by +6; however, the caster must maintain line of effect
to the sensor at all times. If the line of effect is obstructed, the
spell ends. To free the caster of the line of effect restriction for
casting spells through the sensor, multiply the Spellcraft DC by x10. </p>
    <p>The reveal seed has a base Spellcraft DC of 25 if used to
pierce illusions and see things as they really are. The caster can see
through normal and magical darkness, notice secret doors hidden by
magic, see the exact locations of creatures or objects under blur or
displacement effects, see invisible creatures or objects normally, see
through illusions, see onto the Ethereal Plane (but not into
extradimensional spaces), and see the true form of polymorphed,
changed, or transmuted things. The range of such sight is 120 feet. </p>
    <p>The reveal seed can also be used to develop spells that will
do any one of the following: duplicate the read magic spell, comprehend
the written and verbal language of another, or speak in the written or
verbal language of another. To both comprehend and speak a language,
increase the Spellcraft DC by +4. </p>
    <h6><a id="slay"></a><a id="seed-slay"></a>SEED: SLAY </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Necromancy [Death] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 25 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One living
creature 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
partial or half (see text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <p>A spell developed using the slay seed snuffs out the life
force of a living creature, killing it instantly. The slay seed kills a
creature of up to 80 HD. The subject is entitled to a Fortitude saving
throw to survive the attack. If the save is successful, it instead
takes 3d6+20 points of damage. For each additional 80 HD affected (or
each additional creature affected), increase the Spellcraft DC by +8.
Alternatively, a caster can use the slay seed in an epic spell to
suppress the life force of the target by bestowing 2d4 negative levels
on the target (or half as many negative levels on a successful
Fortitude save). For each additional 1d4 negative levels bestowed,
increase the Spellcraft DC by +4. If the subject has at least as many
negative levels as Hit Dice, it dies. If the subject survives and the
negative levels persist for 24 hours or longer, the subject must make
another Fortitude saving throw, or the negative levels are converted to
actual level loss. </p>
    <h6><a id="summon"></a><a id="seed-summon"></a>SEED: SUMMON </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration (Summoning) </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 14 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 75 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> One summoned
creature 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 20 rounds (D) 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates (see text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes
(see text) 
    <p>This seed can summon an outsider. It appears where the caster
designates and acts immediately, on his or her turn, if its spell
resistance is overcome and it fails a Will saving throw. It attacks the
caster&rsquo;s opponents to the best of its ability. If the caster can
communicate with the outsider, he or she can direct it not to attack,
to attack particular enemies, or to perform other actions. The spell
conjures an outsider the caster selects of CR 2 or less. For each +1 CR
of the summoned outsider, increase the Spellcraft DC by +2. For each
additional outsider of the same Challenge Rating summoned, multiply the
Spellcraft DC by x2. When a caster develops a spell with the summon
seed that summons an air, chaotic, earth, evil, fire, good, lawful, or
water creature, the completed spell is also of that type. </p>
    <p>If the caster increases the Spellcraft DC by +10, he or she
can summon a creature of CR 2 or less from another monster type or
subtype. The summoned creature is assumed to have been plucked from
some other plane (or somewhere on the same plane). The summoned
creature attacks the caster&rsquo;s opponents to the best of its
ability; or,
if the caster can communicate with it, it will perform other actions.
However, the summoning ends if the creature is asked to perform a task
inimical to its nature. For each +1 CR of the summoned creature,
increase the Spellcraft DC by +2. </p>
    <p>Finally, by increasing the Spellcraft DC by +60, the caster
can summon a unique individual he or she specifies from anywhere in the
multiverse. The caster must know the target&rsquo;s name and some facts
about
its life, defeat any magical protection against discovery or other
protection possessed by the target, and overcome the target&rsquo;s
spell
resistance, and it must fail a Will saving throw. The target is under
no special compulsion to serve the caster. </p>
    <h6><a id="transform"></a><a id="seed-transform"></a>SEED: TRANSFORM </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Transmutation </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 21 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 300 ft. 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature or
inanimate, nonmagical object 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Permanent 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
negates (see text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <p>Spells using the transform seed change the subject into
another form of creature or object. The new form can range in size from
Diminutive to one size larger than the subject&rsquo;s normal form. For
each
additional increment of size change, increase the Spellcraft DC by +6.
If the caster wants to transform a nonmagical, inanimate object into a
creature of his or her type or transform a creature into a nonmagical,
inanimate object, increase the Spellcraft DC by +10. To change a
creature of one type into another type increase the Spellcraft DC by
+5. </p>
    <p>Transformations involving nonmagical, inanimate substances
with hardness are more difficult; for each 2 points of hardness,
increase the Spellcraft DC by +1. </p>
    <p>To transform a creature into an incorporeal or gaseous form,
increase the Spellcraft DC by +10. Conversely, to overcome the natural
immunity of a gaseous or incorporeal creature to transformation,
increase the Spellcraft DC by +10. </p>
    <p>The transform seed can also change its target into someone
specific. To transform an object or creature into the specific likeness
of another individual (including memories and mental abilities),
increase the Spellcraft DC by +25. If the transformed creature
doesn&rsquo;t
have the level or Hit Dice of its new likeness, it can only use the
abilities of the creature at its own level or Hit Dice. If slain or
destroyed, the transformed creature or object reverts to its original
form. The subject&rsquo;s equipment, if any, remains untransformed or
melds
into the new form&rsquo;s body, at the caster&rsquo;s option. The
transformed
creature or object acquires the physical and natural abilities of the
creature or object it has been changed into while retaining its own
memories and mental ability scores. Mental abilities include
personality, Intelligence, Wisdom, and Charisma scores, level and
class, hit points (despite any change in its Constitution score),
alignment, base attack bonus, base saves, extraordinary abilities,
spells, and spell-like abilities, but not its supernatural abilities.
Physical abilities include natural size and Strength, Dexterity, and
Constitution scores. Natural abilities include armor, natural weapons,
and similar gross physical qualities (presence or absence of wings,
number of extremities, and so forth), and possibly hardness. Creatures
transformed into inanimate objects do not gain the benefit of their
untransformed physical abilities, and may well be blind, deaf, dumb,
and unfeeling. Objects transformed into creatures gain that
creature&rsquo;s
average physical ability scores, but are considered to have mental
ability scores of 0 (the fortify seed can add points to each mental
ability, if desired). For each normal extraordinary ability or
supernatural ability granted to the transformed creature, increase the
Spellcraft DC by +10. The transformed subject can have no more Hit Dice
than the caster has or than the subject has (whichever is greater). In
any case, for each Hit Die the assumed form has above 15, increase the
Spellcraft DC by +2. </p>
    <h6><a id="transport"></a><a id="seed-transport"></a>SEED: TRANSPORT </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Conjuration[Teleportation] </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 27 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You and touched
objects or other touched willing creatures weighing up to 1,000 lb. 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous,
or 5 rounds for temporal transport 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None or
Will negates (see text) 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> No or
Yes (see text) 
    <p>Spells using the transport seed instantly take the caster to a
designated destination, regardless of distance. For interplanar travel,
increase the Spellcraft DC by +4. For each additional 50 pounds in
objects and willing creatures beyond the base 1,000 pounds, increase
the Spellcraft DC by +2. The base use of the transport seed provides
instantaneous travel through the Astral Plane. To shift the
transportation medium to another medium increase the Spellcraft DC by
+2. The caster does not need to make a saving throw, nor is spell
resistance applicable to him or her. Only objects worn or carried
(attended) by another person receive saving throws and spell
resistance. For a spell intended to transport unwilling creatures,
increase the Spellcraft DC by +4. The caster must have at least a
reliable description of the place to which he or she is transporting.
If the caster attempts to use the transport seed with insufficient or
misleading information, the character disappears and simply reappear in
his or her original location. </p>
    <p>As a special use of the transport seed, a caster can develop a
spell that temporarily transports him or her into a different time
stream (leaving the caster in the same physical location); this
increases the Spellcraft DC by +8. If the caster moves him or herself,
or the subject, into a slower time stream for 5 rounds, time ceases to
flow for the subject, and its condition becomes fixed-no force or
effect can harm it until the duration expires. If the caster moves him
or her self into a faster time stream, the caster speeds up so greatly
that all other creatures seem frozen, though they are actually still
moving at their normal speeds. The caster is free to act for 5 rounds
of apparent time. Fire, cold, poison gas, and similar effects can still
harm the caster. While the caster is in the fast time stream, other
creatures are invulnerable to his or her attacks and spells; however,
the caster can create spell effects and leave them to take effect when
he or she reenters normal time. Because of the branching nature of
time, epic spells used to transport a subject into a faster time stream
cannot be made permanent, nor can the duration of 5 rounds be extended.
More simply, the seed can haste or slow a subject for 20 rounds by
transporting it to the appropriate time stream. This decreases the
Spellcraft DC by -4. </p>
    <h6><a id="ward"></a><a id="seed-ward"></a>SEED: WARD </h6>
    <span style={{
      "fontStyle": "italic"
    }}>Abjuration </span>
    <span style={{
      "fontWeight": "bold"
    }}>Spellcraft DC:</span> 14 
    <span style={{
      "fontWeight": "bold"
    }}>Components:</span> V, S 
    <span style={{
      "fontWeight": "bold"
    }}>Casting Time:</span> 1 minute 
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch 
    <span style={{
      "fontWeight": "bold"
    }}>Target or Effect:</span> Touched
creature or object of 2,000 lb. or less; or 10-ft.-radius spherical
emanation, centered on you 
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 24 hours 
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None 
    <span style={{
      "fontWeight": "bold"
    }}>Spell Resistance:</span> Yes 
    <p>This seed can grant a creature protection from damage of a
specified type. The caster can protect a creature from standard damage
or from energy damage. The caster can protect a creature or area from
magic. Alternatively, he or she can hedge out a type of creature from a
specified area. A ward against standard damage protects a creature from
whichever two the caster selects of the three damage types:
bludgeoning, piercing, and slashing. For a ward against all three
types, increase the Spellcraft DC by +4. Each round, the spell created
with the ward seed absorbs the first 5 points of damage the creature
would otherwise take, regardless of whether the source of the damage is
natural or magical. For each additional point of protection, increase
the Spellcraft DC by +2. </p>
    <p>A ward against energy grants a creature protection from
whichever one the caster selects of the five energy types: acid, cold,
electricity, fire, or sonic. Each round, the spell absorbs the first 5
points of damage the creature would otherwise take from the specified
energy type, regardless of whether the source of damage is natural or
magical. The spell protects the recipient&rsquo;s equipment as well.
For each
additional point of protection, increase the Spellcraft DC by +1. </p>
    <p>A ward against a specific type of creature prevents bodily
contact from whichever one of several monster types the caster selects.
This causes the natural weapon attacks of such creatures to fail and
the creatures to recoil if such attacks require touching the warded
creature. The protection ends if the warded creature makes an attack
against or intentionally moves within 5 feet of the blocked creature.
Spell resistance can allow a creature to overcome this protection and
touch the warded creature. </p>
    <p>A ward against magic creates an immobile, faintly shimmering
magical sphere (with radius 10 feet) that surrounds the caster and
excludes all spell effects of up to 1st level. Alternatively, the
caster can ward just the target and not create the radius effect. For
each additional level of spells to be excluded, increase the Spellcraft
DC by +20 (but see below). The area or effect of any such spells does
not include the area of the ward, and such spells fail to affect any
target within the ward. This includes spell-like abilities and spells
or spell-like effects from magic items. However, any type of spell can
be cast through or out of the ward. The caster can leave and return to
the protected area without penalty (unless the spell specifically
targets a creature and does not provide a radius effect). The ward
could be brought down by a targeted dispel magic spell. Epic spells
using the dispel seed may bring down a ward if the enemy spellcaster
succeeds at a caster level check. The ward may also be brought down
with a targeted epic spell using the destroy seed if the enemy
spellcaster succeeds at a caster level check. </p>
    <p>Instead of creating an epic spell that uses the ward seed to
nullify all spells of a given level and lower, the caster can create a
ward that nullifies a specific spell (or specific set of spells). For
each specific spell so nullified, increase the Spellcraft DC by +2 per
spell level above 1st. </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      